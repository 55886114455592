import './Timesheet.css';

import {
  BootstrapDialog,
  CustomInputBase,
  CustomInputBase1,
  StyledTextField,
} from './ModelStyles';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import { CardContent, Grid } from '@material-ui/core';
import {
  createTimesheet,
  getTimesheetList,
  reset,
  submitForReview,
  timesheetSelectors,
  updateTimesheet,
} from '../../features/timesheet/timesheet.Slice';
import {
  getCurrentWeekDays,
  getCurrentWeekNumber,
  getCurrentYear,
  getDaysFromWeekNumber,
  getWeekDaysByWeekNumber,
  getWeeksInYear,
} from '../../utils/index';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CloseIcon from '@mui/icons-material/Close';
import DataTable from '../../common/DataTable/DataTable';
import IconButton from '@mui/material/IconButton';
import Loader from '../../common/Loader/Loader';
import Tooltip from '@mui/material/Tooltip';
import { Typography } from '@mui/material';
import _ from 'underscore';
import { associateSelectors } from '../../features/associate/associateSlice';
import { authSelector } from '../../features/auth/authSlice';
import format from 'date-fns/format';
import { setMessage } from '../../features/notification/notificationSlice';

export default function Timesheet() {
  const {
    isGetTimesheetListSuccess,
    isSubmitForReviewSuccess,
    isSubmitForReviewFailure,
    isUpdateSuccess,
    isUpdateFailure,
    isDeleteSuccess,
    isDeleteFailure,
    isCreateSuccess,
    isCreateFailure,
    createSuccessResponse,
    createFailureResponse,
    timesheetResponse,
    updateResponse,
    deleteResponse,
    isSubmitForReviewSuccessResponse,
    isSubmitForReviewFailureResponse,
  } = useSelector(timesheetSelectors);
  const {
    isGetAssociateListSuccess,

    associateResponse,
  } = useSelector(associateSelectors);

  const [datesOfWeek, setDatesOfWeek] = useState(
    getDaysFromWeekNumber(getCurrentWeekNumber(), getCurrentYear())
  );
  const [daysOfWeek, setDaysOfWeek] = useState(getCurrentWeekDays());

  const [associateList, setAssociateList] = useState([]);
  const { user } = useSelector(authSelector);
  const [Snackbar, setSnackbar] = useState([]);
  const dispatch = useDispatch();
  const [isOpenConfirmation, setIsOpenConfirmation] = useState(false);
  const [currentWeekNumber, setCurrentWeekNumber] = useState(
    getCurrentWeekNumber()
  );
  const [currentYear, setCurrentYear] = useState(getCurrentYear());
  const [timesheetList, setTimesheetList] = useState([]);

  const [editOpen, setEditOpen] = useState(false);
  const [selectedTaskDetails, setSelectedTaskDetails] = useState([]);
  const [editedTask, setEditedTask] = useState({});
  const [loading, setLoading] = useState(false);

  const [isSubmittingForReview, setIsSubmittingForReview] = useState(false);
  const [currentTask, setCurrentTask] = useState(null);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const [currentTask_project_category, setCurrentTask_project_category] =
    useState([]);
  const [currentEstimatedHoursTotal, setCurrentEstimatedHoursTotal] =
    useState('');
  const [columnVisibilityModel, setColumnVisibilityModel] = useState({
    companyId: false,
    id: false,
    dateTimesheet: false,
    task_project_category: true,
    projectId: false,
    associateId: false,
    hours: true,
    dateCreated: false,
  });
  const [inputValues, setInputValues] = useState({});
  const [totalHours, setTotalHours] = useState(0);
  const [forceRender, setForceRender] = useState(false);

  const handleEditClose = () => {
    setEditOpen(false);
  };
  const handleHoursChange = (event) => {
    setCurrentEstimatedHoursTotal(event.target.value);
  };
  const totalHoursChange = (event) => {
    setTotalHours(event.target.value);
  };

  const handleDaysChange = (event, day) => {
    const value = event.target.value;
    setInputValues({ ...inputValues, [day]: value });

    const hours = Object.values({ ...inputValues, [day]: value })
      .filter((hour) => hour !== '')
      .map(Number)
      .reduce((acc, curr) => acc + curr, 0);

    setTotalHours(hours);
  };

  const standardsHoursPerDayValidator = (params) => {
    if (params.props.value > 24) {
      params.props.error = true;
      setSnackbar({
        children: 'Hours cannot be greater than 24',
        severity: 'error',
      });
      return params.props;
    } else {
      params.props.error = false;
      return params.props;
    }
  };

  const checkEmpty = (params) => {
    if (params.props.value === '') {
      params.props.error = true;

      setSnackbar({
        children: 'field cannot be empty',
        severity: 'error',
      });

      return params.props;
    } else {
      params.props.error = false;
      return params.props;
    }
  };

  const hoursRender = (params) => {
    const { field, row } = params;
    if (_.has(row, field)) {
      return row[field]?.workingHours || 0;
    } else {
      return 0;
    }
  };

  const taskRender = (params) => {
    const { row } = params;
    if (
      row &&
      row.name &&
      row.taskId &&
      row.project &&
      row.project.name &&
      row.category
    ) {
      return `[${row.project.name}] - ${row.category} -${row.name}`;
    } else {
      return '';
    }
  };
  const hoursValueSetter = (params) => {
    const { field } = params;
    let hoursValue = {
      workingHours: 0,
      isReviewed: '',
      performanceAppraisal: 0,
    };

    let tempObj = {
      [field]: hoursValue,
    };
    return tempObj;
  };

  const columns = [
    {
      field: 'id',
      headerName: ' S.No.',
      flex: 1,
      minWidth: 150,
      maxWidth: 200,
    },
    {
      field: 'dateTimesheet',
      headerName: 'Date Timesheet',
      flex: 1,

      preProcessEditCellProps: checkEmpty,
    },
    {
      field: 'task_project_category',
      // headerName: "Task[code] - ProjectName - Category",
      headerName: 'ProjectName - Category-Task',
      editable: false,
      flex: 1,
      minWidth: 350,
      valueGetter: taskRender,
      preProcessEditCellProps: checkEmpty,
    },
    {
      field: 'projectId',
      headerName: 'Project Id',
      editable: true,
      flex: 1,
      preProcessEditCellProps: checkEmpty,
    },

    {
      field: 'companyId',
      headerName: 'CompanyId',
      flex: 1,
      editable: true,
      preProcessEditCellProps: checkEmpty,
    },
    {
      field: 'estimatedHoursTotal',
      headerName: ' Total Estimated Hours ',
      flex: 1,
      editable: false,
      minWidth: 250,
      valueGetter: (params) => {
        return params.row?.estimatedHoursTotal + ':00' || '';
      },
      preProcessEditCellProps: checkEmpty,
    },
    {
      field: 'hours',
      headerName: 'Total Actual Hours',
      flex: 1,
      editable: true,
      minWidth: 250,
      valueGetter: (params) => {
        let totalHours = 0;
        for (const key in params.row) {
          if (/^\d{8}$/.test(key)) {
            // check if key is a date in format "YYYYMMDD"
            const hours = params.row[key]?.workingHours;
            if (!isNaN(hours)) {
              totalHours += hours;
            }
          }
        }
        return totalHours;
      },
    },
    {
      field: 'associateId',
      headerName: 'Associate Id',
      flex: 1,
      editable: true,
      preProcessEditCellProps: checkEmpty,
    },
    {
      field: 'dateCreated',
      headerName: 'Date Created',
      flex: 1,
      editable: true,
      preProcessEditCellProps: checkEmpty,
    },
    {
      field: `${datesOfWeek[1]}`,
      headerName: daysOfWeek[1].day,
      minWidth: 120,
      flex: 1,
      editable: true,
      type: 'number',
      preProcessEditCellProps: standardsHoursPerDayValidator,
      valueGetter: hoursRender,
      valueSetter: hoursValueSetter,
    },
    {
      field: `${datesOfWeek[2]}`,
      headerName: daysOfWeek[2].day,
      minWidth: 120,
      flex: 1,
      editable: true,
      type: 'number',
      preProcessEditCellProps: standardsHoursPerDayValidator,
      valueGetter: hoursRender,
      valueSetter: hoursValueSetter,
    },
    {
      field: `${datesOfWeek[3]}`,
      headerName: daysOfWeek[3].day,
      minWidth: 120,
      flex: 1,
      editable: true,
      type: 'number',
      preProcessEditCellProps: standardsHoursPerDayValidator,
      valueGetter: hoursRender,
      valueSetter: hoursValueSetter,
    },
    {
      field: `${datesOfWeek[4]}`,
      headerName: daysOfWeek[4].day,
      minWidth: 120,
      flex: 1,
      editable: true,
      type: 'number',
      preProcessEditCellProps: standardsHoursPerDayValidator,
      valueGetter: hoursRender,
      valueSetter: hoursValueSetter,
    },
    {
      field: `${datesOfWeek[5]}`,
      headerName: daysOfWeek[5].day,
      minWidth: 120,
      flex: 1,
      editable: true,
      type: 'number',
      preProcessEditCellProps: standardsHoursPerDayValidator,
      valueGetter: hoursRender,
      valueSetter: hoursValueSetter,
    },
    {
      field: `${datesOfWeek[6]}`,
      headerName: daysOfWeek[6].day,
      minWidth: 120,
      flex: 1,
      editable: true,
      type: 'number',
      preProcessEditCellProps: standardsHoursPerDayValidator,
      valueGetter: hoursRender,
      valueSetter: hoursValueSetter,
    },
  ];
  const getFormattedHours = (rowData) => {
    let newObj = {};
    daysOfWeek.forEach((eachObj) => {
      if (rowData[eachObj.date]) {
        newObj[eachObj.date] = rowData[eachObj.date].workingHours;
      } else {
        newObj[eachObj.date] = 0;
      }
    });
    setInputValues(newObj);
  };
  const handleEdit = (data) => {
    setCurrentTask(data);
    setIsSubmitting(false);
    getFormattedHours(data);
    const taskProjectCategory = taskRender({ row: data });
    setCurrentTask_project_category(taskProjectCategory);
    setCurrentEstimatedHoursTotal(data.estimatedHoursTotal);
    setSelectedTaskDetails(data);
    setEditOpen(true);
    setEditedTask(data);
  };

  const handleEditTask = async () => {
    setIsSubmitting(true);
    setEditOpen(false);

    const user = JSON.parse(localStorage.getItem('user'));
    const formattedInputValues = formatInputValuesToDate(inputValues);

    if (formattedInputValues.length > 0) {
      const promises = formattedInputValues.map((eD) => {
        const existingData = eD.dateCreated;
        const actionPayload = {
          hours: eD.value,
          associateId: user.associateId,
          companyId: user.companyId,
          dateTimesheet: parseInt(eD.dateCreated),
          projectId: editedTask.project.projectId,
          taskId: selectedTaskDetails.taskId,
        };
        if (
          editedTask[existingData] !== undefined &&
          editedTask[existingData] !== null
        ) {
          if (editedTask[existingData].workingHours !== parseInt(eD.value)) {
            return dispatch(updateTimesheet(actionPayload));
          }

          return Promise.resolve();
        } else {
          return dispatch(createTimesheet(actionPayload));
        }
      });

      try {
        await Promise.all(promises);
        await dispatch(
          getTimesheetList({
            associateId: user.associateId,
            currentWeekNumber: currentWeekNumber,
            currentYear: currentYear,
          })
        );
      } catch (error) {
      } finally {
        setIsSubmitting(false);
      }
    } else {
      setIsSubmitting(false);
    }

    setTotalHours('');
  };

  const handleSubmit = () => {
    setIsSubmittingForReview(true);
    setIsSubmitting(false);
    setIsOpenConfirmation(true);
  };

  useEffect(() => {
    const currentWeekNumber = getCurrentWeekNumber();
    const currentYear = getCurrentYear();
    dispatch(
      getTimesheetList({
        associateId: user.associateId,
        currentWeekNumber: currentWeekNumber,
        currentYear: currentYear,
      })
    );
  }, []);

  useEffect(() => {
    if (!isGetTimesheetListSuccess) {
      dispatch(
        getTimesheetList({
          associateId: user.associateId,
          currentWeekNumber: currentWeekNumber,
          currentYear: currentYear,
        })
      );
    }
  }, [
    isGetTimesheetListSuccess,
    dispatch,
    user.associateId,
    currentWeekNumber,
    currentYear,
  ]);

  useEffect(() => {
    if (associateResponse) {
      let users = [];
      associateResponse?.map((user) => {
        let userD = {
          value: user.associateId,
          label: user.firstName + ' ' + user.lastName,
        };
        users = [...users, userD];
      });
      setAssociateList(users);
    }
  }, [isGetAssociateListSuccess, associateResponse]);
  useEffect(() => {
    if (timesheetResponse) {
      setTimesheetList(timesheetResponse);
    }
  }, [isGetTimesheetListSuccess, timesheetResponse]);

  useEffect(() => {
    const currentWeekNumber = getCurrentWeekNumber();
    const currentYear = getCurrentYear();
    if (isCreateSuccess) {
      if (createSuccessResponse.result) {
        dispatch(
          setMessage({
            message: createSuccessResponse.result[0],
            status: createSuccessResponse.status,
          })
        );
      }
      dispatch(
        getTimesheetList({
          associateId: user.associateId,
          currentWeekNumber: currentWeekNumber,
          currentYear: currentYear,
        })
      );
      setIsSubmitting(false);
      setEditOpen(false);
    }
  }, [isCreateSuccess, createSuccessResponse]);
  useEffect(() => {
    const currentWeekNumber = getCurrentWeekNumber();
    const currentYear = getCurrentYear();
    if (
      isCreateFailure &&
      createFailureResponse &&
      createFailureResponse.error &&
      createFailureResponse.error[0]
    ) {
      dispatch(
        setMessage({
          message: createFailureResponse.error,
          status: 'error',
        })
      );
      dispatch(
        getTimesheetList({
          associateId: user.associateId,
          currentWeekNumber: currentWeekNumber,
          currentYear: currentYear,
        })
      );
    }
    dispatch(reset());
    setEditOpen(false);
  }, [isCreateFailure, createFailureResponse]);
  useEffect(() => {
    const currentWeekNumber = getCurrentWeekNumber();
    const currentYear = getCurrentYear();
    if (isUpdateSuccess) {
      dispatch(
        setMessage({
          message: updateResponse[0],
          status: 'Success',
        })
      );
      dispatch(
        getTimesheetList({
          associateId: user.associateId,
          currentWeekNumber: currentWeekNumber,
          currentYear: currentYear,
        })
      );
      setIsSubmitting(false);
      setEditOpen(false);
    }
  }, [isUpdateSuccess, updateResponse]);
  useEffect(() => {
    const currentWeekNumber = getCurrentWeekNumber();
    const currentYear = getCurrentYear();
    if (
      isUpdateFailure &&
      updateResponse &&
      updateResponse.error &&
      updateResponse.error[0]
    ) {
      dispatch(
        setMessage({
          message: updateResponse.result,
          status: 'error',
        })
      );
      dispatch(
        getTimesheetList({
          associateId: user.associateId,
          currentWeekNumber: currentWeekNumber,
          currentYear: currentYear,
        })
      );
    }
    dispatch(reset());
    setEditOpen(false);
  }, [isUpdateFailure, updateResponse]);
  useEffect(() => {
    const currentWeekNumber = getCurrentWeekNumber();
    const currentYear = getCurrentYear();
    if (isSubmitForReviewSuccess) {
      if (isSubmitForReviewSuccessResponse.result) {
        dispatch(
          setMessage({
            message: 'TimeSheet Submitted For Review Successfully',
            status: isSubmitForReviewSuccessResponse.status,
          })
        );
      }
      dispatch(
        getTimesheetList({
          associateId: user.associateId,
          currentWeekNumber: currentWeekNumber,
          currentYear: currentYear,
        })
      );
      dispatch(reset());
      setIsSubmitting(false);
    } else if (isSubmitForReviewFailure && isSubmitForReviewFailureResponse) {
      dispatch(
        setMessage({
          message: isSubmitForReviewFailureResponse.error,
          status: 'error',
        })
      );
      dispatch(
        getTimesheetList({
          associateId: user.associateId,
          currentWeekNumber: currentWeekNumber,
          currentYear: currentYear,
        })
      );
      dispatch(reset());
      setIsSubmitting(false);
    }
  }, [
    isSubmitForReviewSuccess,
    isSubmitForReviewSuccessResponse,
    isSubmitForReviewFailure,
    isSubmitForReviewFailureResponse,
  ]);
  useEffect(() => {
    if (timesheetResponse) {
      setTimesheetList(timesheetResponse);
    }
  }, [timesheetResponse]);

  useEffect(() => {
    const currentWeekNumber = getCurrentWeekNumber();
    const currentYear = getCurrentYear();
    if (isDeleteSuccess || isDeleteFailure) {
      dispatch(
        setMessage({
          message: deleteResponse.result,
          status: deleteResponse.status,
        })
      );
      dispatch(
        getTimesheetList({
          associateId: user.associateId,
          currentWeekNumber: currentWeekNumber,
          currentYear: currentYear,
        })
      );
    }
  }, [isDeleteSuccess, deleteResponse, isDeleteFailure]);
  useEffect(() => {
    if (editOpen) {
      const existingTotalHours = Object.values(inputValues).reduce(
        (acc, curr) => acc + Number(curr || 0),
        0
      );
      setTotalHours(existingTotalHours);
    }
  }, [editOpen, inputValues]);
  function formatInputValuesToDate(inputValues) {
    let formattedInputValues = [];
    for (const [day, value] of Object.entries(inputValues)) {
      let oD = {
        dateCreated: day,
        value,
      };
      formattedInputValues.push(oD);
    }
    return formattedInputValues;
  }

  const submitConfirmDialog = () => {
    return (
      <>
        <Dialog maxWidth='xs' open={isOpenConfirmation}>
          <DialogTitle>Are you sure?</DialogTitle>
          <DialogContent dividers>
            You want to Submit this TimeSheet.
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleConfirmationNo}
              variant='outlined'
              color='error'
              size='small'
              startIcon={<CancelIcon />}
            >
              No
            </Button>
            <Button
              onClick={handleConfirmationYes}
              variant='outlined'
              color='success'
              size='small'
              startIcon={<CheckCircleIcon />}
            >
              Yes
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  };

  const handlePreviousWeek = () => {
    setLoading(true);
    const today = new Date();
    const previousWeekStartDate = getNextMonday(
      currentWeekNumber - 1,
      getCurrentYear()
    );
    const weekDates = getDaysFromWeekNumber(
      currentWeekNumber - 1,
      getCurrentYear()
    );

    if (weekDates && previousWeekStartDate < today) {
      const previousWeekNumber = currentWeekNumber - 1;
      setCurrentWeekNumber(previousWeekNumber);

      const datesArray = Object.values(weekDates);

      let weekDays = getWeekDaysByWeekNumber(previousWeekNumber);

      setDatesOfWeek([...datesArray]);
      setDaysOfWeek([...weekDays]);
      setForceRender((prev) => !prev);
      dispatch(
        getTimesheetList({
          associateId: user.associateId,
          currentWeekNumber: previousWeekNumber,
          currentYear: getCurrentYear(),
        })
      ).finally(() => setLoading(false));
    } else {
      setLoading(false);
    }
  };

  const handleNextWeek = () => {
    setLoading(true);
    const today = new Date();
    const nextWeekStartDate = getNextMonday(
      currentWeekNumber + 1,
      getCurrentYear()
    );
    if (nextWeekStartDate > today) {
      setLoading(false);
      return;
    }

    const nextWeekNumber = currentWeekNumber + 1;
    setCurrentWeekNumber(nextWeekNumber);

    let weekDays = getWeekDaysByWeekNumber(nextWeekNumber);
    let weekDates = getDaysFromWeekNumber(nextWeekNumber, getCurrentYear());

    setDatesOfWeek([...weekDates]);
    setDaysOfWeek([...weekDays]);
    setForceRender((prev) => !prev);
    dispatch(
      getTimesheetList({
        associateId: user.associateId,
        currentWeekNumber: nextWeekNumber,
        currentYear: getCurrentYear(),
      })
    ).finally(() => setLoading(false));
  };

  const getTimeSheetsForWeek = (weekNumber, year) => {
    let currentWeekNumber = weekNumber,
      currentYear = year;

    if (weekNumber === 0) {
      currentYear = year - 1;
      const weeks = getWeeksInYear(year - 1);
      currentWeekNumber = weeks;
    }
    dispatch(
      getTimesheetList({
        associateId: user.associateId,
        currentWeekNumber: currentWeekNumber,
        currentYear: currentYear,
      })
    );
  };
  function getNextMonday(weekNumber, year) {
    const date = new Date();
    date.setFullYear(year, 0, 1);
    date.setDate(date.getDate() + (weekNumber - 1) * 7 - date.getDay());

    while (date.getDay() !== 1) {
      date.setDate(date.getDate() + 1);
    }

    return date;
  }

  function getNextSaturday(weekNumber, year) {
    const startDate = getNextMonday(weekNumber, year);
    const endDate = new Date(startDate);

    endDate.setDate(endDate.getDate() + 5);

    return endDate;
  }
  useEffect(() => {
    getTimeSheetsForWeek(currentWeekNumber, currentYear);
  }, [currentWeekNumber, currentYear]);

  const handleConfirmationNo = () => {
    setIsOpenConfirmation(false);
  };
  const handleConfirmationYes = () => {
    setIsSubmitting(true);
    const user = JSON.parse(localStorage.getItem('user'));
    const timeSheetData = [];

    let totalHours = 0;
    let hasSubmittableTasks = false;
    timesheetList.forEach((timesheet) => {
      Object.keys(timesheet).forEach((key) => {
        if (key.startsWith('20')) {
          const dayData = timesheet[key];
          if (
            dayData &&
            (dayData.isReviewed === 'No' || dayData.isReviewed === 'Reject')
          ) {
            hasSubmittableTasks = true;
            let timesheetObj = {};
            timesheetObj.dateTimesheet = key;
            timesheetObj.workingHours = timesheet[key]
              ? timesheet[key].workingHours
              : 0;
            timesheetObj.isReviewed = timesheet[key]
              ? timesheet[key].isReviewed
              : '';
            timesheetObj.performanceAppraisal = timesheet[key]
              ? timesheet[key].performanceAppraisal
              : 0;
            timesheetObj.taskId = timesheet.taskId;
            timesheetObj.hours = timesheet.totalHours;
            timesheetObj.weekNum = currentWeekNumber;
            timesheetObj.yearVal = getCurrentYear();
            timesheetObj.associateId = user.associateId;
            timesheetObj.companyId = user.companyId;
            timeSheetData.push(timesheetObj);

            totalHours += timesheetObj.workingHours;
          }
        }
      });
    });
    if (!hasSubmittableTasks) {
      dispatch(
        setMessage({
          message:
            'All tasks are either reviewed or pending. No tasks available for submission.',
          status: 'info',
        })
      );
      setIsSubmitting(false);
      return;
    }
    if (totalHours === 0) {
      dispatch(
        setMessage({
          message:
            'Total hours cannot be zero. Please enter working hours for at least one timesheet.',
          status: 'error',
        })
      );
      setIsSubmitting(false);
      return;
    }
    const data = {
      timesheetData: timeSheetData,
    };

    dispatch(submitForReview(data));
    setIsOpenConfirmation(false);
  };
  const handleEditTimesheet = (row) => {
    const isReviewedStatus = Object.values(row)
      .filter((value) => typeof value === 'object' && value !== null)
      .some(
        (dayData) =>
          dayData.isReviewed === 'Yes' || dayData.isReviewed === 'Pending'
      );
    if (isReviewedStatus) {
      return;
    }
    setEditOpen(true);
    setCurrentTask(row);
    handleEdit(row);
  };

  return (
    <>
      {submitConfirmDialog()}
      <div className='page-header'>
        <div className='page-title'>
          <Typography variant='h6'>Time sheets</Typography>
        </div>
        <div className='centered-content'>
          <Tooltip title='Previous Week'>
            <IconButton className='icon-button' onClick={handlePreviousWeek}>
              <ArrowBackIosNewIcon style={{ color: '#164037' }} />
            </IconButton>
          </Tooltip>
          <div className='week-info'>
            <div className='startWeek'>
              <div>Start Date </div>
              <div>
                {format(
                  getNextMonday(currentWeekNumber, getCurrentYear()),
                  'dd/MMM/yyyy'
                )}{' '}
              </div>
            </div>
            <div className='endWeek'>
              <div>End Date </div>
              {format(
                getNextSaturday(currentWeekNumber, getCurrentYear()),
                'dd/MMM/yyyy'
              )}
            </div>
          </div>

          <Tooltip title='Next Week'>
            <IconButton className='icon-button' onClick={handleNextWeek}>
              <ArrowForwardIosIcon style={{ color: '#164037' }} />
            </IconButton>
          </Tooltip>
        </div>
        <div
          className={`add-task-button ${
            isSubmittingForReview ? 'submitting' : ''
          }`}
        >
          <Button variant='contained' onClick={handleSubmit}>
            <Box
              sx={{
                textTransform: 'capitalize',
              }}
            >
              Submit For Review
            </Box>
          </Button>
        </div>
      </div>

      <Box
        sx={{
          '& .MuiDataGrid-columnHeaders': {
            fontSize: 15,
            fontFamily: 'Poppins, sans-serif',
          },
        }}
      >
        <div className='timesheetgrid'>
          <DataTable
            key={forceRender}
            columns={columns}
            rows={timesheetList}
            pageSize={8}
            checkboxSelection={false}
            isEditTimesheetEnable={true}
            getRowId={(row) => row.associateId}
            columnVisibilityModel={columnVisibilityModel}
            handleEditTimesheet={handleEditTimesheet}
            addButtonText='Add/Update Tasks'
          ></DataTable>
        </div>
      </Box>

      <p className='note-text2'>
        NOTE: "If you do not complete the timesheet, you are entitled to loss
        that week's pay"
      </p>

      {isSubmitting && (
        <Loader
          open={isSubmitting}
          handleClose={() => setIsSubmitting(false)}
        />
      )}
      {loading && (
        <Loader open={loading} handleClose={() => setLoading(false)} />
      )}
      <div>
        {editOpen && (
          <BootstrapDialog
            onClose={handleEditClose}
            aria-labelledby='edit-time-sheet-dialog-title'
            open={editOpen}
            maxWidth='md'
            fullWidth
          >
            <DialogTitle id='edit-time-sheet-dialog-title'>
              Edit Time Sheet
              <CloseIcon
                className='CloseIconStyles'
                onClick={handleEditClose}
              />
            </DialogTitle>
            <DialogContent dividers>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  marginBottom: '1rem',
                }}
              >
                <CustomInputBase className='CustomInputBaseStyles'>
                  <p className='CustomInputBaseTitleStyles'>
                    Task, Project, Category: {currentTask_project_category}
                  </p>
                </CustomInputBase>
              </div>

              <CardContent>
                <Grid container spacing={2}>
                  {daysOfWeek.map((dayObject) => {
                    const dayOfWeek = dayObject.day.split(' ')[0];
                    const isEditable =
                      dayObject.date >= currentTask.startDate &&
                      dayObject.date <= currentTask.endDate;
                    const textFieldStyle = isEditable
                      ? { backgroundColor: '#fff' }
                      : { backgroundColor: '#f5f5f5', borderColor: '#ccc' };
                    if (dayOfWeek !== 'Sun') {
                      return (
                        <Grid
                          item
                          xs={12}
                          sm={6}
                          md={4}
                          lg={2}
                          key={dayObject.day}
                        >
                          <CustomInputBase1>
                            <StyledTextField
                              label={dayObject.day}
                              value={inputValues[dayObject.date] || ''}
                              onChange={(e) =>
                                handleDaysChange(e, dayObject.date)
                              }
                              InputProps={{
                                readOnly: !isEditable,
                                style: textFieldStyle,
                              }}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              fullWidth
                            />
                          </CustomInputBase1>
                        </Grid>
                      );
                    }
                    return null;
                  })}
                </Grid>
                <br />
                <div
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  <CustomInputBase style={{ flex: 1, marginRight: '1rem' }}>
                    <StyledTextField
                      label='Total Estimated Hours'
                      value={currentEstimatedHoursTotal}
                      onChange={handleHoursChange}
                      InputProps={{ readOnly: true }}
                      InputLabelProps={{ style: { textAlign: 'center' } }}
                      fullWidth
                    />
                  </CustomInputBase>
                  <CustomInputBase style={{ flex: 1 }}>
                    <StyledTextField
                      label='Total Actual Hours'
                      value={totalHours}
                      onChange={totalHoursChange}
                      InputProps={{ readOnly: true }}
                      InputLabelProps={{ style: { textAlign: 'center' } }}
                      fullWidth
                    />
                  </CustomInputBase>
                </div>
              </CardContent>
            </DialogContent>
            <DialogActions>
              <Button
                autoFocus
                onClick={() =>
                  handleEditTask({
                    hours: totalHours,
                    dateCreated: inputValues,
                  })
                }
                variant='contained'
                color='primary'
              >
                Save
              </Button>
              <Button
                autoFocus
                onClick={handleEditClose}
                variant='outlined'
                color='secondary'
              >
                Cancel
              </Button>
            </DialogActions>
          </BootstrapDialog>
        )}
      </div>
    </>
  );
}
