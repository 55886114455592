import { Dialog, TextField } from '@mui/material';

import { styled } from '@mui/material/styles';

// BootstrapDialog component styling
export const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogTitle-root': {
    backgroundColor: 'rgb(22, 64, 55)',
    color: theme.palette.common.white,
    fontWeight: theme.typography.fontWeightBold,
  },
  '& .MuiDialogContent-root': {
    padding: theme.spacing(8),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(2),
  },
}));

// CustomInputBase component styling
export const CustomInputBase = styled('div')(({ theme }) => ({
  marginBottom: theme.spacing(2),
  padding: theme.spacing(1),
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: theme.shape.borderRadius,
}));

// CustomInputBase1 component styling
export const CustomInputBase1 = styled('div')(({ theme }) => ({
  padding: theme.spacing(1),
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: theme.shape.borderRadius,
  backgroundColor: theme.palette.background.paper,
}));

// StyledTextField component styling
export const StyledTextField = styled(TextField)(({ theme, readOnly }) => ({
  '& .MuiInputBase-root': {
    borderRadius: theme.shape.borderRadius,
    borderColor: readOnly ? '#ccc' : theme.palette.primary.main,
    backgroundColor: readOnly ? '#f5f5f5' : '#fff',
    color: readOnly ? '#666' : theme.palette.text.primary,
  },
  '& .MuiInputLabel-root': {
    color: readOnly ? '#999' : theme.palette.text.primary,
  },
}));
