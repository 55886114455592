import './Loader.css';

import Backdrop from '@mui/material/Backdrop';
import PropTypes from 'prop-types';
import React from 'react';

function Loader({ open, handleClose }) {
  return (
    <Backdrop open={open} className='loader-container' onClick={handleClose}>
      <div className='loader' />
    </Backdrop>
  );
}

Loader.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default Loader;
