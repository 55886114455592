import './Signup.css';

import * as React from 'react';
import * as Yup from 'yup';

import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { authSelector, signup } from '../../features/auth/authSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

import Box from '@mui/material/Box';
import CardContent from '@mui/material/CardContent';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Loader from '../../common/Loader/Loader';
import LoadingButton from '@mui/lab/LoadingButton';
import { Paper } from '@mui/material';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { setMessage } from '../../features/notification/notificationSlice';
import { useFormik } from 'formik';

export default function Signup() {
  const navigate = useNavigate();
  const { isSignupFailure, isSignupSuccess, SignupResponse } =
    useSelector(authSelector);
  const dispatch = useDispatch();
  const [Loading, setLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const SignUpschema = Yup.object().shape({
    CompanyName: Yup.string().required('Company Name is a required field'),
    webSiteName: Yup.string(),

    email: Yup.string()
      .email('Email must be a valid email address')
      .required('Email is required')
      .matches(/@zyxantech\.com$/, 'Email must be from zyxantech.com'),
    firstName: Yup.string()
      .min(2, 'Too Short!')
      .max(50, 'Too Long!')
      .required('Firstname is required'),

    lastName: Yup.string()
      .min(2, 'Too Short!')
      .max(50, 'Too Long!')
      .required('Lastname is required'),
    PhoneNumber: Yup.number().required('Phone number is required'),
  });

  const formik = useFormik({
    initialValues: {
      CompanyName: '',
      WebSiteName: '',
      PhoneNumber: '',
      email: '',
      firstName: '',
      lastName: '',
    },
    onSubmit: (data) => {
      handleSubmit(data);
    },
    validationSchema: SignUpschema,
  });

  const handleSubmit = (data) => {
    const {
      CompanyName,
      WebSiteName,
      PhoneNumber,
      email,
      firstName,
      lastName,
    } = data;
    setIsSubmitting(true);
    dispatch(
      signup({
        name: CompanyName,
        webSiteName: WebSiteName,
        phone: PhoneNumber,
        email,
        firstName,
        lastName,
      })
    );
  };

  useEffect(() => {
    // eslint-disable-next-line no-undef
    if (isSignupSuccess) {
      setIsSubmitting(false);
      dispatch(
        setMessage({
          message: SignupResponse.result,
          status: SignupResponse.status,
        })
      );
      navigate('/login');
    }
  }, [isSignupSuccess, SignupResponse, dispatch]);

  useEffect(() => {
    if (isSignupFailure && SignupResponse) {
      setIsSubmitting(false);
      dispatch(
        setMessage({
          message: SignupResponse.error,
          status: 'error',
        })
      );
    }
  }, [isSignupFailure, SignupResponse, dispatch]);

  return (
    <div className='form'>
      <CardContent>
        <Container
          component='main'
          maxWidth='xs'
          sx={{ maxWidth: '600px !important' }}
        >
          <Box
            sx={{
              marginTop: 8,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Paper elevation={3} sx={{ padding: 3, marginTop: '-2rem' }}>
              <div>
                <img
                  src='./assets/Zyxan log3-01.png'
                  alt=''
                  style={{ width: '95px', height: '95px' }}
                />
              </div>
              <Typography
                component='h1'
                variant='h5'
                style={{ marginLeft: '8rem' }}
              >
                Register Your Company
              </Typography>
              <Box
                component='form'
                noValidate
                onSubmit={handleSubmit}
                sx={{ mt: 3 }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      required
                      fullWidth
                      name='CompanyName'
                      label='Company Name'
                      type='text'
                      id='CompanyName'
                      value={formik.values.CompanyName}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.CompanyName &&
                        Boolean(formik.errors.CompanyName)
                      }
                      helperText={
                        formik.touched.CompanyName && formik.errors.CompanyName
                      }
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      // required
                      fullWidth
                      name=' WebsiteName'
                      label='Website Name'
                      type='text'
                      id='WebsiteName'
                      value={formik.values.WebSite}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.WebSite && Boolean(formik.errors.WebSite)
                      }
                      helperText={
                        formik.touched.WebSite && formik.errors.WebSite
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      autoComplete='given-name'
                      name='firstName'
                      required
                      fullWidth
                      id='firstName'
                      label='FirstName'
                      value={formik.values.firstName}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.firstName &&
                        Boolean(formik.errors.firstName)
                      }
                      helperText={
                        formik.touched.firstName && formik.errors.firstName
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      required
                      fullWidth
                      id='lastName'
                      label='Last Name'
                      name='lastName'
                      autoComplete='Last Name'
                      value={formik.values.lastName}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.lastName &&
                        Boolean(formik.errors.lastName)
                      }
                      helperText={
                        formik.touched.lastName && formik.errors.lastName
                      }
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      required
                      fullWidth
                      id='email'
                      label='Email Address'
                      name='email'
                      value={formik.values.email}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.email && Boolean(formik.errors.email)
                      }
                      helperText={formik.touched.email && formik.errors.email}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      required
                      fullWidth
                      name='PhoneNumber'
                      label='Phone Number'
                      type='tel'
                      id='PhoneNumber'
                      value={formik.values.PhoneNumber}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.PhoneNumber &&
                        Boolean(formik.errors.PhoneNumber)
                      }
                      helperText={
                        formik.touched.PhoneNumber && formik.errors.PhoneNumber
                      }
                    />
                  </Grid>
                </Grid>
                <LoadingButton
                  loading={Loading}
                  type='submit'
                  fullWidth
                  variant='contained'
                  sx={{ mt: 3, mb: 2 }}
                  onClick={formik.handleSubmit}
                >
                  Register
                </LoadingButton>
                <Grid container justifyContent='flex-end'>
                  <Grid item>
                    <Link
                      href='#'
                      variant='body2'
                      component={RouterLink}
                      to='/login'
                    >
                      Already have an account? Sign in
                    </Link>
                  </Grid>
                </Grid>
              </Box>
            </Paper>
          </Box>
        </Container>
      </CardContent>
      <Loader open={isSubmitting} handleClose={() => setIsSubmitting(false)} />
    </div>
  );
}
