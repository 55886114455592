import './DataTable.css';

import { Box, Button, Typography } from '@mui/material';
import { Cancel, Done } from '@material-ui/icons';
import {
  DataGrid,
  GridActionsCellItem,
  GridRowModes,
  GridToolbar,
} from '@mui/x-data-grid';
import { useCallback, useEffect, useMemo, useState } from 'react';

import AddIcon from '@mui/icons-material/Add';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import Fab from '@mui/material/Fab';
import GridWrapper from '../GridWrapper/GridWrapper';
import IconButton from '@mui/material/IconButton';
import NoRowsOverlay from './NoData';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import VisibilityIcon from '@mui/icons-material/Visibility';
import _ from 'underscore';
import { format } from 'date-fns';

const CellWrapper = ({ value }) => {
  // Check if value is a Date object
  const displayValue =
    value instanceof Date ? format(value, 'MM/dd/yyyy') : value;

  return (
    <div
      style={{
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        maxWidth: '100%',
        fontFamily: 'Poppins, sans-serif',
      }}
    >
      {displayValue}
    </div>
  );
};
const DataTable = ({
  rows,
  columns,
  pageSize = 100,
  checkboxSelection = true,
  showToolbar = false,
  editMode = 'row',
  isEditEnabled = false,
  isDeleteEnabled = false,
  isExtendTaskEnable = false,
  isAddAssociateEnable = false,
  isFabEnable = false,
  isApproveEnabled = false,
  isRejectEnabled = false,
  handleEdit,
  handleDelete,
  handleExtendTask,
  handleAddAssociate,
  handleEditTimesheet,
  isAddEnabled,
  handleApprove,
  handleReject,
  addButtonText,
  handleAdd,
  title,
  columnVisibilityModel,
  forceRender = false,
  isReset = false,
  isEditTimesheetEnable,
  isReviewedStatus,
  isTodoActionsEnable,
  handleViewTaskDetails,
}) => {
  const [gridRows, setGridRows] = useState([]);
  const [gridColumns, setGridColumns] = useState([]);

  const [rowModesModel, setRowModesModel] = useState({});
  const [isAddingNewRow, setIsAddingNewRow] = useState(false);
  const [currentlyEditedRowId, setCurrentlyEditedRowId] = useState(null);
  const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState(true);
  const [isUpdateButtonDisabled, setIsUpdateButtonDisabled] = useState(true);
  const [editedRowId, setEditedRowId] = useState(null);

  useEffect(() => {
    setGridRows(rows);
  }, [rows]);

  useEffect(() => {}, [gridRows]);
  const handleEditClick = (id) => () => {
    if (currentlyEditedRowId || isAddingNewRow) {
      return;
    }

    setRowModesModel((prevModel) => ({
      ...prevModel,
      [id]: { mode: GridRowModes.Edit, isAddingNew: false },
    }));
    setCurrentlyEditedRowId(id);
    setEditedRowId(id);
  };
  const handleUpdateClick = (id) => () => {
    try {
      setRowModesModel({
        ...rowModesModel,
        [id]: { mode: GridRowModes.View },
      });
      setIsAddingNewRow(false);
      setCurrentlyEditedRowId(null);
      setEditedRowId(null);
    } catch (error) {}
  };

  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    setGridRows(rows);
    setIsAddingNewRow(false);
    setCurrentlyEditedRowId(null);
    setEditedRowId(null);
  };

  useEffect(() => {
    if (isReset === true) {
      setRowModesModel({
        ...rowModesModel,
        [editedRowId]: { mode: GridRowModes.View, ignoreModifications: true },
      });
      setGridRows(rows);

      setIsAddingNewRow(false);
      setCurrentlyEditedRowId(null);
      setEditedRowId(null);
    }
  }, [isReset]);
  useEffect(() => {
    let newColumns = [];
    if (
      isEditEnabled ||
      isDeleteEnabled ||
      isExtendTaskEnable ||
      isAddAssociateEnable ||
      isFabEnable ||
      isEditTimesheetEnable ||
      isTodoActionsEnable
    ) {
      newColumns.push({
        field: 'edit',
        headerName: 'Actions',
        flex: 1,
        minWidth: 450,
        renderCell: (params) => {
          const { id } = params;
          const { actionType } = params.row;
          const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

          if (isInEditMode) {
            return [
              <GridActionsCellItem
                key='update'
                icon={
                  <IconButton
                    variant='outlined'
                    size='small'
                    color='success'
                    sx={{ textTransform: 'capitalize', border: 0 }}
                  >
                    <Button
                      id='Save'
                      size='small'
                      variant='outlined'
                      color='success'
                      startIcon={<CheckCircleIcon color='success' />}
                      sx={{ textTransform: 'capitalize' }}
                    >
                      {rowModesModel[id]?.isAddingNew ? 'Save' : 'Update'}
                    </Button>
                  </IconButton>
                }
                label={rowModesModel[id]?.isAddingNew ? 'Save' : 'Update'}
                onClick={handleUpdateClick(id)}
              />,
              <GridActionsCellItem
                key='cancel'
                icon={
                  <IconButton
                    variant='outlined'
                    size='small'
                    color='error'
                    sx={{ textTransform: 'capitalize' }}
                  >
                    <Button
                      id='Cancel'
                      size='small'
                      variant='outlined'
                      color='error'
                      startIcon={<CancelIcon color='error' />}
                      sx={{ textTransform: 'capitalize' }}
                    >
                      Cancel
                    </Button>
                  </IconButton>
                }
                label='Cancel'
                className='textPrimary'
                onClick={handleCancelClick(id)}
                color='inherit'
              />,
            ];
          } else {
            return [
              isEditEnabled ? (
                <GridActionsCellItem
                  key='edit'
                  icon={
                    <IconButton
                      variant='outlined'
                      size='small'
                      color='primary'
                      sx={{ textTransform: 'capitalize' }}
                    >
                      <Button
                        id='Edit'
                        size='small'
                        variant='outlined'
                        color='primary'
                        startIcon={<EditIcon color='primary' />}
                        sx={{ textTransform: 'capitalize' }}
                      >
                        Edit
                      </Button>
                    </IconButton>
                  }
                  label='Edit'
                  className='textPrimary'
                  onClick={handleEditClick(id)}
                  color='inherit'
                />
              ) : null,
              isDeleteEnabled ? (
                <GridActionsCellItem
                  key='delete'
                  icon={
                    <IconButton>
                      <Button
                        id='Delete'
                        size='small'
                        color='error'
                        variant='outlined'
                        startIcon={<DeleteIcon />}
                        sx={{ textTransform: 'capitalize' }}
                      >
                        Delete
                      </Button>
                    </IconButton>
                  }
                  label='Delete'
                  className='textPrimary'
                  onClick={() => handleDelete(id)}
                  color='inherit'
                />
              ) : null,
              isExtendTaskEnable ? (
                <GridActionsCellItem
                  key='extendTask'
                  icon={
                    <IconButton>
                      <Button
                        id='Extend'
                        size='small'
                        color='primary'
                        variant='outlined'
                        startIcon={<AddIcon />}
                        sx={{ textTransform: 'capitalize' }}
                      >
                        ExtendTask
                      </Button>
                    </IconButton>
                  }
                  label='ExtendTask'
                  className='textPrimary'
                  onClick={() => handleExtendTask(params.row)}
                  color='inherit'
                />
              ) : null,
              isAddAssociateEnable ? (
                <GridActionsCellItem
                  key='addAssociate'
                  icon={
                    <IconButton>
                      <Button
                        id='addAssociate'
                        size='small'
                        color='success'
                        variant='outlined'
                        startIcon={<PersonAddIcon />}
                        sx={{ textTransform: 'capitalize', height: 'auto' }}
                      >
                        AddAssociate &emsp;
                      </Button>
                    </IconButton>
                  }
                  label='addAssociate'
                  className='textPrimary'
                  onClick={() => handleAddAssociate(params.row)}
                  color='inherit'
                />
              ) : null,
              isFabEnable ? (
                <GridActionsCellItem
                  icon={
                    <IconButton>
                      <Tooltip title='Number of associates in this project'>
                        <Fab
                          size='small'
                          color='success'
                          variant='outlined'
                          cursor='none'
                          style={{ height: 'auto', cursor: 'default' }}
                        >
                          {params.row.members
                            ? params.row.members.length > 0
                              ? params.row.members.length
                              : 0
                            : 0}
                        </Fab>
                      </Tooltip>
                    </IconButton>
                  }
                />
              ) : null,
              isEditTimesheetEnable ? (
                <GridActionsCellItem
                  key='EditTimesheet'
                  icon={
                    <IconButton
                      variant='outlined'
                      size='small'
                      color='primary'
                      sx={{ textTransform: 'capitalize' }}
                      onClick={() => handleEditTimesheet(params.row)}
                    >
                      <Button
                        size='small'
                        variant='outlined'
                        color='success'
                        sx={{ textTransform: 'capitalize' }}
                        disabled={Object.values(params.row)
                          .filter(
                            (value) =>
                              typeof value === 'object' && value !== null
                          )
                          .some(
                            (dayData) =>
                              dayData.isReviewed === 'Yes' ||
                              dayData.isReviewed === 'Pending'
                          )}
                      >
                        Enter Actual hours
                      </Button>
                    </IconButton>
                  }
                  label='EditTimesheet'
                  className='textPrimary'
                  color='inherit'
                />
              ) : null,
              isTodoActionsEnable &&
              (actionType === 'Leave Request' ||
                actionType === 'Extend Task' ||
                actionType === 'Leave Update' ||
                actionType === 'Leave Cancelation') ? (
                <>
                  <GridActionsCellItem
                    key='Approve'
                    icon={
                      <IconButton
                        variant='outlined'
                        size='small'
                        color='primary'
                        sx={{ textTransform: 'capitalize' }}
                      >
                        <Button
                          size='small'
                          variant='outlined'
                          color='success'
                          startIcon={<Done color='success' />}
                          sx={{ textTransform: 'capitalize' }}
                          onClick={() => handleApprove(params.row)}
                        >
                          Approve
                        </Button>
                      </IconButton>
                    }
                    label='Approve'
                    className='textPrimary'
                    color='inherit'
                  />
                  <GridActionsCellItem
                    key='Reject'
                    icon={
                      <IconButton
                        variant='outlined'
                        size='small'
                        color='primary'
                        sx={{ textTransform: 'capitalize' }}
                      >
                        <Button
                          size='small'
                          variant='outlined'
                          color='error'
                          startIcon={<Cancel color='error' />}
                          sx={{ textTransform: 'capitalize' }}
                          onClick={() => handleReject(params.row)}
                        >
                          Reject
                        </Button>
                      </IconButton>
                    }
                    label='Reject'
                    className='textPrimary'
                    color='inherit'
                  />
                </>
              ) : actionType === 'Submit For Review' ? (
                <GridActionsCellItem
                  key='View'
                  icon={
                    <IconButton
                      variant='outlined'
                      size='small'
                      color='primary'
                      sx={{ textTransform: 'capitalize' }}
                    >
                      <Button
                        size='small'
                        variant='outlined'
                        color='primary'
                        startIcon={<VisibilityIcon color='primary' />}
                        sx={{ textTransform: 'capitalize' }}
                        onClick={() => handleViewTaskDetails(params.row)}
                      >
                        View
                      </Button>
                    </IconButton>
                  }
                  label='View'
                  className='textPrimary'
                  color='inherit'
                />
              ) : null,
            ];
          }
        },
        preProcessEditCellProps: (params) => {},
      });
    }

    const updatedColumns = columns.map((column) => ({
      ...column,
      renderCell: (params) => <CellWrapper value={params.value} />,
    }));
    newColumns = [...updatedColumns, ...newColumns];

    setGridColumns(newColumns);
  }, [
    columns,
    rowModesModel,
    isEditEnabled,
    isDeleteEnabled,
    isExtendTaskEnable,
    isAddAssociateEnable,
    isEditTimesheetEnable,
    isFabEnable,
    handleEdit,
    handleDelete,
    handleExtendTask,
    handleAddAssociate,
    isAddEnabled,
    handleApprove,
    handleReject,
    addButtonText,
    forceRender,
    isReset,
  ]);

  const handleRowEditStart = (params, event) => {
    event.defaultMuiPrevented = true;
  };

  const handleProcessRowUpdateError = useCallback((error) => {}, []);

  const handleRowEditStop = (params, event) => {
    event.defaultMuiPrevented = true;
  };

  const processRowUpdate = (newRow, event) => {
    const updatedRow = { ...newRow, isNew: false };
    setGridRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));

    if (typeof updatedRow.id == 'string' && updatedRow.id.includes('new')) {
      handleAdd(updatedRow);
    } else {
      handleEdit(updatedRow);
    }
    return newRow;
  };

  const rowMode = useMemo(() => {
    return editMode === 'row' ? GridRowModes.EditRow : GridRowModes.CellEdit;
  }, [editMode]);

  const handleNewRow = (e) => {
    e.preventDefault();
    if (isAddingNewRow || currentlyEditedRowId) {
      return;
    }

    setIsAddingNewRow(true);
    const randomId = `new${Math.random().toString(36).substr(2, 9)}`;

    const columnNames = _.pluck(gridColumns, 'field');

    let newRow = columnNames.map((column) => {
      if (column === 'id') {
        return { id: randomId };
      }
      return { [column]: '' };
    });

    newRow = Object.assign({}, ...newRow);
    setGridRows((prevRows) => [newRow, ...prevRows]);
    setRowModesModel((prevModel) => ({
      ...prevModel,
      [randomId]: { mode: GridRowModes.Edit, isAddingNew: true },
    }));
  };

  return (
    <Box m='10px' height='80vh'>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Box>
          <Typography
            variant='h6'
            sx={{
              fontWeight: '500',
            }}
          >
            {title}
          </Typography>
        </Box>
        <Box>
          {isAddEnabled ? (
            <Box m={2}>
              <Stack direction='row' justifyContent='end'>
                <Button
                  id='Add'
                  size='small'
                  onClick={handleNewRow}
                  variant='contained'
                  startIcon={<AddIcon />}
                  sx={{
                    textTransform: 'capitalize',
                    fontWeight: 500,
                  }}
                >
                  {addButtonText ? addButtonText : 'Add New'}
                </Button>
              </Stack>
            </Box>
          ) : (
            <></>
          )}
        </Box>
      </Box>

      <GridWrapper gridHeight={566}>
        <DataGrid
          key={forceRender}
          rows={gridRows}
          columns={gridColumns}
          pageSize={pageSize}
          editMode={editMode}
          rowModesModel={rowModesModel}
          columnVisibilityModel={columnVisibilityModel}
          onRowEditStart={handleRowEditStart}
          onRowEditStop={handleRowEditStop}
          processRowUpdate={processRowUpdate}
          onProcessRowUpdateError={handleProcessRowUpdateError}
          checkboxSelection={checkboxSelection}
          experimentalFeatures={{ newEditingApi: true }}
          components={{
            Toolbar: showToolbar ? GridToolbar : undefined,
            NoRowsOverlay,
          }}
          className='custom-datagrid'
          rowHeight={50}
          style={{ border: '2px solid rgb(22 64 55)', borderRadius: '10px' }}
        ></DataGrid>
      </GridWrapper>
    </Box>
  );
};
export default DataTable;
