import './Task.css';

import * as React from 'react';

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import {
  associateSelectors,
  getAssociateList,
} from '../../features/associate/associateSlice';
import {
  createTask,
  deleteTask,
  getCurrentWeekTasks,
  getTaskDetails,
  reset,
  taskSelector,
  updateTask,
} from '../../features/task/taskSlice';
import {
  extendTask,
  getTaskExtendStatus,
  todoSelector,
} from '../../features/todo/todoSlice';
import {
  getAssociateProjects,
  getProjectsList,
  projectSelectors,
} from '../../features/Projects/Projectslice';
import {
  getCurrentWeekNumber,
  getCurrentYear,
  getDaysFromWeekNumber,
  getWeeksInYear,
} from '../../utils/index';
import {
  getHolidaysList,
  holidaySelectors,
} from '../../features/holidays/holidaysSlice';
import { getLeaveList, leaveSelector } from '../../features/Leaves/LeaveSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import Button from '@mui/material/Button';
import CancelIcon from '@mui/icons-material/Cancel';
import CloseIcon from '@mui/icons-material/Close';
import DataTable from '../../common/DataTable/DataTable';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import Loader from '../../common/Loader/Loader';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import PropTypes from 'prop-types';
import TextArea from 'antd/es/input/TextArea';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import { Typography } from '@mui/material';
import { authSelector } from '../../features/auth/authSlice';
import dayjs from 'dayjs';
import { reset as extendReset } from '../../features/todo/todoSlice';
import format from 'date-fns/format';
import { formateDate } from '../../utils/date';
import moment from 'moment/moment';
import { selectOptionFormatter } from '../../utils/dataGrid';
import { setMessage } from '../../features/notification/notificationSlice';
import { styled } from '@mui/material/styles';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle xs={{ m: 0, p: 8 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label='close'
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function Task() {
  const currentUser = useSelector(authSelector);

  const {
    taskResponse,
    isGetTaskListSuccess,
    isGetCurrentWeekTasksSuccess,
    isCreateTaskSuccess,
    isCreateTaskFailure,
    isUpdateTaskSuccess,
    isUpdateTaskFailure,
    isDeleteSuccess,
    isDeleteFailure,
    deleteResponse,
    createResponse,
    updateResponse,
    getCurrentWeekTasksResponse,
  } = useSelector(taskSelector);
  const { isGetAssociateListSuccess, associateResponse } =
    useSelector(associateSelectors);
  const {
    isGetAssociateProjectsSuccess,
    getAssociateProjectsResponse,
    isGetProjectsListSuccess,
    projectsResponse,
  } = useSelector(projectSelectors);
  const { holidaysResponse } = useSelector(holidaySelectors);
  const { leaveResponse } = useSelector(leaveSelector);
  const [holidayDates, setHolidayDates] = useState([]);
  const [leaveDates, setLeaveDates] = useState([]);
  const { isExtendTaskSuccess, isExtendTaskFailure, extendTaskResponse } =
    useSelector(todoSelector);
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const [projectsList, setProjectsList] = useState([]);
  const [projectsList2, setProjectsList2] = useState([]);
  const [associateList, setAssociateList] = useState([]);
  const [Snackbar, setSnackbar] = useState([]);
  const [taskList, setTaskList] = useState([]);
  const [isOpenConfirmation, setIsOpenConfirmation] = useState(false);
  const [deleteRowId, setDeleteRowId] = useState(null);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  //  const { user } = useSelector(authSelector);
  const user = JSON.parse(localStorage.getItem('user'));
  const loggedInAssociateId = user?.associateId;
  const [columnVisibilityModel, setColumnVisibilityModel] = useState({
    //id: false,
    // taskId: false,
    id: false,
  });
  const [isEditEnabled, setIsEditEnabled] = useState(true);
  const [isExtendTaskEnable, setIsExtendTaskEnabled] = useState(true);
  const [currentEndDate, setCurrentEndDate] = useState(new Date());
  const [extendEndDate, setExtendEndDate] = useState(new Date());
  const [reasonForExtend, setReasonForExtend] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isReset, setIsReset] = useState(false);
  const [currentWeekNumber, setCurrentWeekNumber] = useState(
    getCurrentWeekNumber()
  );
  const [datesOfWeek, setDatesOfWeek] = useState(
    getDaysFromWeekNumber(getCurrentWeekNumber(), getCurrentYear())
  );
  const handleInputChange = (e) => {
    const { id, value } = e.target;
    if (id === 'extendEndDate') {
      setExtendEndDate(value);
    }
    if (id === 'reasonForExtend') {
      setReasonForExtend(value);
    }
  };
  const getWeekStartDate = (weekNumber, year) =>
    getNextMonday(weekNumber, year);

  const calculateMinDate = () => {
    const currentWeekStartDate = getWeekStartDate(
      currentWeekNumber,
      getCurrentYear()
    );

    return dayjs(currentWeekStartDate).toDate();
  };
  const renderPriorityCell = (params) => {
    let cellColor = '#000';
    if (params.value === 'High') {
      cellColor = '#ff0000';
    } else if (params.value === 'Medium') {
      cellColor = '#ff9900';
    } else if (params.value === 'Low') {
      cellColor = '#009900';
    }

    return (
      <div
        style={{
          backgroundColor: cellColor,
          color: '#fff',
          padding: '8px',
          borderRadius: '8px',
          width: '91px',
          textAlign: 'center',
        }}
      >
        {params.value}
      </div>
    );
  };
  const getTasksForWeek = (weekNumber, currentYear) => {
    let currentWeekNumber = weekNumber,
      currentNewYear = currentYear;
    if (weekNumber === 0) {
      currentNewYear = currentYear - 1;
      const weeks = getWeeksInYear(currentYear - 1);
      currentWeekNumber = weeks;
    }
    const startOfWeek = getDaysFromWeekNumber(
      currentWeekNumber,
      currentNewYear
    )[0];
    const endOfWeek = getDaysFromWeekNumber(
      currentWeekNumber,
      currentNewYear
    )[6];

    let startDate = startOfWeek;
    let endDate = endOfWeek;

    dispatch(
      getCurrentWeekTasks({ associateId: user.associateId, startDate, endDate })
    );
  };
  const handlePreviousWeek = () => {
    const today = new Date();
    const previousWeekStartDate = getNextMonday(
      currentWeekNumber - 1,
      getCurrentYear()
    );
    if (previousWeekStartDate < today) {
      const previousWeekNumber = currentWeekNumber - 1;
      setCurrentWeekNumber(previousWeekNumber);
      setIsReset(true);
    }
  };
  const handleNextWeek = () => {
    const currentDate = new Date();
    const nextWeekStartDate = getNextMonday(
      currentWeekNumber + 1,
      getCurrentYear()
    );
    if (nextWeekStartDate > currentDate) {
      return;
    }
    setCurrentWeekNumber(currentWeekNumber + 1);
    setIsReset(true);
  };

  const checkEmpty = (params) => {
    if (params.props.value === '') {
      params.props.error = true;

      setSnackbar({
        children: 'field cannot be empty',
        severity: 'error',
      });

      return params.props;
    } else {
      params.props.error = false;
      return params.props;
    }
  };
  const [extendTaskData, setExtendTaskData] = useState({});

  const handleExtendTask = (rowData) => {
    setCurrentEndDate(rowData.endDate);
    setExtendTaskData(rowData);
    setOpen(true);
  };
  const ExtendTask = () => {
    setIsSubmitting(true);
    setOpen(false);
    if (extendEndDate <= currentEndDate) {
      dispatch(
        setMessage({
          message: 'Extended end date must be after the current end date',
          status: 'error',
        })
      );
      setIsSubmitting(false);
      setOpen(true);
      return;
    }
    if (reasonForExtend.trim() === '') {
      dispatch(
        setMessage({
          message: 'Please provide a reason for extending the task',
          status: 'error',
        })
      );
      setIsSubmitting(false);
      setOpen(true);
      return;
    }
    const user = JSON.parse(localStorage.getItem('user'));
    const formattedExtendEndDate = format(extendEndDate, 'yyyyMMdd');
    dispatch(
      extendTask({
        taskId: extendTaskData.taskId,
        projectId: extendTaskData.projectId,
        extendEndDate: formattedExtendEndDate,
        reasonForExtend: reasonForExtend,
        associateId: user.associateId,
        companyId: user.companyId,
      })
    ).then(() => {
      setExtendEndDate(new Date());
      setReasonForExtend('');
      setIsSubmitting(false);
    });
  };

  const handleClose = () => {
    setOpen(false);
    setExtendEndDate(new Date());
    setReasonForExtend('');
    setIsSubmitting(false);
  };

  const columns = [
    {
      field: 'id',
      headerName: 'Id',
      flex: 1,
      editable: false,
    },
    {
      field: 'projectTitle',
      headerName: ' Project',
      width: 200,
      editable: true,
      type: 'singleSelect',
      valueFormatter: selectOptionFormatter,
      valueOptions: user.isAdmin ? projectsList2 : projectsList,
      valueGetter: (params) => {
        return params.row?.projectTitle || '';
      },
    },

    {
      field: 'title',
      headerName: 'Task Title',
      editable: true,
      width: 250,
    },
    {
      field: 'description',
      headerName: 'Task Description',
      width: 400,
      editable: true,
    },
    {
      field: 'priority',
      headerName: 'Priority',
      editable: true,
      width: 150,
      renderCell: renderPriorityCell,
      type: 'singleSelect',
      valueOptions: ['High', 'Medium', 'Low'],
    },

    {
      field: 'taskCategory',
      headerName: 'Task Category',
      //    flex: 1,
      editable: true,
      width: 200,
      type: 'singleSelect',
      valueOptions: [
        'Testing',
        'Development',
        'Documentation',
        'Designing',
        'KT',
        'Meetings',
        'Environment Setups',
      ],
    },

    {
      field: 'startDate',
      headerName: 'Start Date',
      width: 200,
      type: 'date',
      editable: true,
      valueFormatter: (params) => dayjs(params.value).format('DD/MMM/YYYY'),
      renderEditCell: (params) => (
        <DatePicker
          value={params.value ? dayjs(params.value).$d : null}
          onChange={(newValue) => {
            const selectedDate = newValue ? dayjs(newValue).$d : null;

            params.api.setEditCellValue({
              id: params.id,
              field: params.field,
              value: selectedDate,
            });
          }}
          renderInput={(inputParams) => <TextField {...inputParams} />}
          //disablePast
          minDate={calculateMinDate()}
          shouldDisableDate={(date) => {
            const formattedDate = dayjs(date).format('YYYY-MM-DD');
            return (
              dayjs(date).day() === 0 ||
              holidayDates.some((holiday) =>
                dayjs(date).isSame(dayjs(holiday), 'day')
              ) ||
              leaveDates.includes(formattedDate)
            );
          }}
        />
      ),
    },
    {
      field: 'endDate',
      headerName: 'End Date',
      width: 200,
      type: 'date',
      editable: true,
      valueFormatter: (params) => dayjs(params.value).format('DD/MMM/YYYY'),
      renderEditCell: (params) => (
        <DatePicker
          value={params.value ? dayjs(params.value).$d : null}
          onChange={(newValue) => {
            const selectedDate = newValue ? dayjs(newValue).$d : null;

            params.api.setEditCellValue({
              id: params.id,
              field: params.field,
              value: selectedDate,
            });
          }}
          renderInput={(inputParams) => <TextField {...inputParams} />}
          //disablePast
          minDate={calculateMinDate()}
          shouldDisableDate={(date) => {
            const formattedDate = dayjs(date).format('YYYY-MM-DD');
            return (
              dayjs(date).day() === 0 || // Disable Sundays
              holidayDates.some((holiday) =>
                dayjs(date).isSame(dayjs(holiday), 'day')
              ) ||
              leaveDates.includes(formattedDate)
            );
          }}
        />
      ),
    },
    {
      field: 'estimatedHoursTotal',
      headerName: '  Total Estimated Hours',
      editable: true,
      width: 200,
    },
    {
      field: 'estimatedHoursPerDay',
      headerName: 'Estimated Hours PerDay',
      width: 200,
      editable: true,
      valueFormatter: (params) => Math.round(params?.value),
      preProcessEditCellProps: (params) => {
        const startDate = moment(params.otherFieldsProps?.startDate.value);
        const endDate = moment(params.otherFieldsProps?.endDate.value);
        const difference = endDate.diff(startDate, 'days');
        if (difference === 0) {
          return {
            ...params.props,
            value: params.otherFieldsProps?.estimatedHoursTotal.value,
          };
        } else {
          const estimatedHoursTotal =
            params.otherFieldsProps?.estimatedHoursTotal.value /
              (difference + 1) || 0;

          return {
            ...params.props,
            value: estimatedHoursTotal ? estimatedHoursTotal : 0,
          };
        }
      },
    },
  ];
  if (user.isAdmin === true) {
    columns.push({
      field: 'assignedTo',
      headerName: 'Assigned To',
      type: 'singleSelect',
      valueFormatter: selectOptionFormatter,
      valueOptions:
        user.isAdmin === true
          ? associateList
          : [
              {
                value: loggedInAssociateId,
                label: `${user?.firstName} ${user?.lastName}`,
              },
            ],
      valueGetter: (params) => {
        return params.row?.assignedTo || '';
      },
      editable: true,
      width: 250,
      hide: true,
      preProcessEditCellProps: checkEmpty,
    });
  }
  useEffect(() => {
    const currentWeekNumber = getCurrentWeekNumber();
    const currentYear = getCurrentYear();
    const startOfWeek = getDaysFromWeekNumber(
      currentWeekNumber,
      currentYear
    )[0];
    const endOfWeek = getDaysFromWeekNumber(currentWeekNumber, currentYear)[6];

    let startDate = startOfWeek;
    let endDate = endOfWeek;
    dispatch(
      getCurrentWeekTasks({
        associateId: user.associateId,
        startDate: startDate,
        endDate: endDate,
      })
    );
  }, []);
  useEffect(() => {
    dispatch(getAssociateProjects(user.associateId));
    dispatch(getAssociateList(user.companyId));
    dispatch(getProjectsList(user.companyId));
    dispatch(getTaskExtendStatus());
    dispatch(getTaskDetails());
    dispatch(getHolidaysList());
    dispatch(getLeaveList(user.associateId));
  }, []);
  useEffect(() => {
    if (holidaysResponse) {
      const mandatoryHolidays = holidaysResponse
        .filter((holiday) => holiday.status === 'Mandatory')
        .map((holiday) => formateDate(holiday.holidaysDates + ''));
      setHolidayDates(mandatoryHolidays);
    }
  }, [holidaysResponse]);
  useEffect(() => {
    if (leaveResponse) {
      const approvedLeaveDates = leaveResponse.flatMap((leave) => {
        if (leave.approvalStatus === 'Approve') {
          const startDate = dayjs(leave.fromDate.toString(), 'YYYYMMDD');
          const endDate = dayjs(leave.toDate.toString(), 'YYYYMMDD');
          let dates = [];
          for (
            let date = startDate;
            date.isBefore(endDate.add(1, 'day'));
            date = date.add(1, 'day')
          ) {
            dates.push(date.format('YYYY-MM-DD'));
          }
          return dates;
        }
        return [];
      });
      setLeaveDates(approvedLeaveDates);
    }
  }, [leaveResponse]);

  function handleAdd(data) {
    setIsSubmitting(true);
    const user = JSON.parse(localStorage.getItem('user'));
    const isAdmin = user.isAdmin === true;

    if (
      !data.projectTitle ||
      !data.title ||
      !data.startDate ||
      !data.endDate ||
      !data.estimatedHoursTotal
    ) {
      dispatch(
        setMessage({
          message: 'Please fill in all required fields',
          status: 'error',
        })
      );
      setIsSubmitting(false);
      return;
    }
    const formattedStartDate = format(data.startDate, 'yyyyMMdd');
    const formattedEndDate = format(data.endDate, 'yyyyMMdd');
    const assignedTo = isAdmin ? data.assignedTo : user.associateId;
    const currentWeekNumber = getCurrentWeekNumber();
    const currentYear = getCurrentYear();
    const weekDates = getDaysFromWeekNumber(currentWeekNumber, currentYear);
    const weekStartDate = weekDates[0];
    const weekEndDate = weekDates[6];
    if (formattedEndDate < weekStartDate || formattedEndDate > weekEndDate) {
      dispatch(
        setMessage({
          message: 'End date is outside the current week.',
          status: 'error',
        })
      );
      setIsSubmitting(false);
      return;
    }
    if (formattedStartDate > formattedEndDate) {
      dispatch(
        setMessage({
          message: 'Start date cannot be after the end date.',
          status: 'error',
        })
      );
      setIsSubmitting(false);
      return;
    }
    dispatch(
      createTask({
        projectId: data.projectTitle,
        title: data.title,
        description: data.description,
        priority: data.priority,
        taskCategory: data.taskCategory,
        startDate: formattedStartDate,
        endDate: formattedEndDate,
        estimatedHoursTotal: data.estimatedHoursTotal,
        estimatedHoursPerDay: data.estimatedHoursPerDay,
        assignedTo: assignedTo,
        taskStatus: 'Open',
        createdBy: data.createdBy,
        dateCreated: data.dateCreated,
        sessionId: data.sessionId,
        companyId: data.companyId,
      })
    );
  }
  function handleEdit(data) {
    setIsSubmitting(true);
    const user = JSON.parse(localStorage.getItem('user'));
    const isAdmin = user.isAdmin === true;
    const associateId = isAdmin ? data.assignedTo : user.associateId;
    const formattedStartDate = format(data.startDate, 'yyyyMMdd');
    const formattedEndDate = format(data.endDate, 'yyyyMMdd');
    const currentWeekNumber = getCurrentWeekNumber();
    const currentYear = getCurrentYear();
    const weekDates = getDaysFromWeekNumber(currentWeekNumber, currentYear);
    const weekStartDate = weekDates[0];
    const weekEndDate = weekDates[6];
    if (formattedEndDate < weekStartDate || formattedEndDate > weekEndDate) {
      dispatch(
        setMessage({
          message: 'End date is outside the current week.',
          status: 'error',
        })
      );
      setIsSubmitting(false);
      return;
    }
    if (formattedStartDate > formattedEndDate) {
      dispatch(
        setMessage({
          message: 'Start date cannot be after the end date.',
          status: 'error',
        })
      );
      setIsSubmitting(false);
      return;
    }
    if (user.isAdmin === true) {
      let assignedTo;
      if (!isNaN(parseFloat(data.assignedTo)) && isFinite(data.assignedTo)) {
        assignedTo = parseInt(data.assignedTo);
      } else {
        dispatch(
          setMessage({
            message: 'Please Enter The assignedTo',
            status: 'error',
          })
        );
        setIsSubmitting(false);
        return;
      }
    }
    let projectTitle;
    if (!isNaN(parseFloat(data.projectTitle)) && isFinite(data.projectTitle)) {
      projectTitle = parseInt(data.projectTitle);
    } else {
      dispatch(
        setMessage({
          message: 'Please Select The projectTitle',
          status: 'error',
        })
      );
      setIsSubmitting(false);
      return;
    }
    dispatch(
      updateTask({
        taskId: data.taskId,
        projectId: data.projectTitle,
        title: data.title,
        description: data.description,
        priority: data.priority,
        taskCategory: data.taskCategory,
        startDate: formattedStartDate,
        endDate: formattedEndDate,
        estimatedHoursTotal: data.estimatedHoursTotal,
        estimatedHoursPerDay: data.estimatedHoursPerDay,
        assignedTo: associateId,
        taskStatus: 'Open',
        createdBy: data.createdBy,
        dateCreated: data.dateCreated,
        sessionId: data.sessionId,
        companyId: data.companyId,
      })
    );
  }
  function getNextMonday(weekNumber, year) {
    const date = new Date();
    date.setFullYear(year, 0, 1);
    date.setDate(date.getDate() + (weekNumber - 1) * 7 - date.getDay());

    // Find the next Monday
    while (date.getDay() !== 1) {
      date.setDate(date.getDate() + 1);
    }

    return date;
  }

  function getNextSaturday(weekNumber, year) {
    const startDate = getNextMonday(weekNumber, year);
    const endDate = new Date(startDate);

    // Add 5 days to get to Saturday
    endDate.setDate(endDate.getDate() + 5);

    return endDate;
  }

  useEffect(() => {
    getTasksForWeek(currentWeekNumber, getCurrentYear());
  }, [currentWeekNumber]);

  function handleDelete(id) {
    setIsOpenConfirmation(true);
    setDeleteRowId(id);
  }

  useEffect(() => {
    if (projectsResponse) {
      let projects = [];
      projectsResponse.map((project) => {
        let projectD = {
          value: project.projectId,
          label: project.title,
        };
        projects = [...projects, projectD];
      });

      setProjectsList2(projects);
    }
  }, [isGetProjectsListSuccess, projectsResponse]);
  useEffect(() => {
    if (getAssociateProjectsResponse) {
      let projects = [];
      getAssociateProjectsResponse.map((project) => {
        let projectD = {
          value: project.projectId,
          label: project.title,
        };
        projects = [...projects, projectD];
      });

      setProjectsList(projects);
    }
  }, [isGetAssociateProjectsSuccess, getAssociateProjectsResponse]);

  useEffect(() => {
    if (associateResponse && Array.isArray(associateResponse)) {
      let users = [];
      associateResponse.map((user) => {
        let userD = {
          value: user.associateId,
          label: user.firstName + ' ' + user.lastName,
        };
        users = [...users, userD];
      });
      setAssociateList(users);
    }
  }, [isGetAssociateListSuccess, associateResponse]);
  useEffect(() => {
    if (
      isGetCurrentWeekTasksSuccess &&
      Array.isArray(getCurrentWeekTasksResponse) &&
      Array.isArray(associateResponse) &&
      (user.isAdmin
        ? Array.isArray(projectsResponse)
        : Array.isArray(getAssociateProjectsResponse))
    ) {
      const newArray = getCurrentWeekTasksResponse.map((v) => {
        const associate = associateResponse.find(
          (data) => v.assignedTo === data.associateId
        );

        const projectsArray = user.isAdmin
          ? projectsResponse
          : getAssociateProjectsResponse;

        const project = projectsArray.find(
          (data) => v.projectId === data.projectId
        );

        return {
          ...v,
          id: v.taskId,
          startDate: formateDate(v.startDate + ''),
          endDate: formateDate(v.endDate + ''),
          assignedTo: associate
            ? associate.firstName + ' ' + associate.lastName
            : '',
          projectTitle: project ? project.title : 'N/A',
        };
      });

      setTaskList(newArray);
      setIsReset(false);
    }
  }, [
    isGetCurrentWeekTasksSuccess,
    getCurrentWeekTasksResponse,
    associateResponse,
    projectsResponse,
    getAssociateProjectsResponse,
    user.isAdmin,
  ]);

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('user'));
    const loggedInAssociateId = user?.associateId;
    const currentWeekNumber = getCurrentWeekNumber();
    const currentYear = getCurrentYear();
    const startOfWeek = getDaysFromWeekNumber(
      currentWeekNumber,
      currentYear
    )[0];
    const endOfWeek = getDaysFromWeekNumber(currentWeekNumber, currentYear)[6];

    let startDate = startOfWeek;
    let endDate = endOfWeek;
    if (isCreateTaskSuccess && createResponse) {
      dispatch(
        setMessage({
          message: createResponse.result,
          status: createResponse.status,
        })
      );
      dispatch(
        getCurrentWeekTasks({
          associateId: user.associateId,
          startDate: startDate,
          endDate: endDate,
        })
      );
    }
    dispatch(reset());
    setIsSubmitting(false);
  }, [isCreateTaskSuccess, createResponse]);
  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('user'));
    const loggedInAssociateId = user?.associateId;
    const currentWeekNumber = getCurrentWeekNumber();
    const currentYear = getCurrentYear();
    const startOfWeek = getDaysFromWeekNumber(
      currentWeekNumber,
      currentYear
    )[0];
    const endOfWeek = getDaysFromWeekNumber(currentWeekNumber, currentYear)[6];

    let startDate = startOfWeek;
    let endDate = endOfWeek;
    if (isCreateTaskFailure && createResponse) {
      dispatch(
        setMessage({
          message: createResponse.error,
          status: 'error',
        })
      );
      dispatch(
        getCurrentWeekTasks({
          associateId: user.associateId,
          startDate: startDate,
          endDate: endDate,
        })
      );
    }
    dispatch(reset());
    setIsSubmitting(false);
  }, [isCreateTaskFailure, createResponse]);

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('user'));
    const loggedInAssociateId = user?.associateId;
    const currentWeekNumber = getCurrentWeekNumber();
    const currentYear = getCurrentYear();
    const startOfWeek = getDaysFromWeekNumber(
      currentWeekNumber,
      currentYear
    )[0];
    const endOfWeek = getDaysFromWeekNumber(currentWeekNumber, currentYear)[6];

    let startDate = startOfWeek;
    let endDate = endOfWeek;
    if (isUpdateTaskSuccess && updateResponse) {
      dispatch(
        setMessage({
          message: updateResponse.result,
          status: updateResponse.status,
        })
      );
      dispatch(
        getCurrentWeekTasks({
          associateId: user.associateId,
          startDate: startDate,
          endDate: endDate,
        })
      );
    }
    dispatch(reset());
    setIsSubmitting(false);
  }, [isUpdateTaskSuccess, updateResponse]);
  useEffect(() => {
    const currentWeekNumber = getCurrentWeekNumber();
    const currentYear = getCurrentYear();
    const startOfWeek = getDaysFromWeekNumber(
      currentWeekNumber,
      currentYear
    )[0];
    const endOfWeek = getDaysFromWeekNumber(currentWeekNumber, currentYear)[6];

    let startDate = startOfWeek;
    let endDate = endOfWeek;
    if (isUpdateTaskFailure && updateResponse) {
      dispatch(
        setMessage({
          message: updateResponse.error,
          status: 'error',
        })
      );
    }
    dispatch(reset());
    setIsSubmitting(false);
  }, [isUpdateTaskFailure, updateResponse]);

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('user'));
    const loggedInAssociateId = user?.associateId;
    const currentWeekNumber = getCurrentWeekNumber();
    const currentYear = getCurrentYear();
    const startOfWeek = getDaysFromWeekNumber(
      currentWeekNumber,
      currentYear
    )[0];
    const endOfWeek = getDaysFromWeekNumber(currentWeekNumber, currentYear)[6];

    let startDate = startOfWeek;
    let endDate = endOfWeek;
    if (isDeleteSuccess || isDeleteFailure) {
      dispatch(
        setMessage({
          message: deleteResponse.result,
          status: deleteResponse.status,
        })
      );
      dispatch(
        getCurrentWeekTasks({
          associateId: user.associateId,
          startDate: startDate,
          endDate: endDate,
        })
      );
    }
    dispatch(reset());
    setIsSubmitting(false);
  }, [isDeleteSuccess, deleteResponse, isDeleteFailure]);
  useEffect(() => {
    if (isExtendTaskSuccess) {
      dispatch(
        setMessage({
          message: 'Task extended successfully..',
          status: 'Success',
        })
      );
      dispatch(getTaskExtendStatus());
      setIsSubmitting(false);
      dispatch(extendReset());
    }
  }, [isExtendTaskSuccess, extendTaskResponse]);

  useEffect(() => {
    if (isExtendTaskFailure) {
      dispatch(
        setMessage({
          message: extendTaskResponse?.result,
          status: extendTaskResponse?.status,
        })
      );
      setIsSubmitting(false);
      dispatch(reset());
    }
  }, [isExtendTaskFailure, extendTaskResponse]);

  useEffect(() => {
    return () => {
      dispatch(reset());
    };
  }, []);

  const deleteConfirmDialog = () => {
    return (
      <>
        <Dialog maxWidth='xs' open={isOpenConfirmation}>
          <DialogTitle>Are you sure?</DialogTitle>
          <DialogContent dividers>
            You want to Delete this Task.its irretrievable you lost the data
          </DialogContent>
          <DialogActions>
            <Button
              // ref={noButtonRef}
              onClick={handleConfirmationNo}
              variant='outlined'
              color='error'
              size='small'
              startIcon={<CancelIcon />}
            >
              No
            </Button>
            <Button
              onClick={handleConfirmationYes}
              variant='outlined'
              color='success'
              size='small'
              startIcon={<DeleteIcon />}
            >
              Yes
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  };

  const handleConfirmationNo = () => {
    setIsOpenConfirmation(false);
  };

  const handleConfirmationYes = () => {
    setIsSubmitting(true);
    dispatch(deleteTask(deleteRowId));
    setIsOpenConfirmation(false);
  };

  return (
    <>
      {deleteConfirmDialog()}
      <div className='page-header'>
        <div className='page-title'>
          <Typography variant='h6'>Tasks</Typography>
        </div>
        <div className='centered-content'>
          <Tooltip title='Previous Week'>
            <IconButton className='icon-button' onClick={handlePreviousWeek}>
              <ArrowBackIosNewIcon style={{ color: '#164037' }} />
            </IconButton>
          </Tooltip>
          <div className='week-info'>
            <div className='startWeek'>
              <div>Start Date </div>
              <div>
                {format(
                  getNextMonday(currentWeekNumber, getCurrentYear()),
                  'dd/MMM/yyyy'
                )}{' '}
              </div>
            </div>
            <div className='endWeek'>
              <div>End Date </div>
              {format(
                getNextSaturday(currentWeekNumber, getCurrentYear()),
                'dd/MMM/yyyy'
              )}
            </div>
          </div>

          <Tooltip title='Next Week'>
            <IconButton className='icon-button' onClick={handleNextWeek}>
              <ArrowForwardIosIcon style={{ color: '#164037' }} />
            </IconButton>
          </Tooltip>
        </div>
        <div className='add-task-button'>
          <div className='btn'></div>
        </div>
      </div>

      <div className='taskgrid'>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <DataTable
            columns={columns}
            rows={taskList}
            pageSize={13}
            isReset={isReset}
            checkboxSelection={false}
            isDeleteEnabled={true}
            isEditEnabled={true}
            isExtendTaskEnable={true}
            isAddEnabled={true}
            columnVisibilityModel={columnVisibilityModel}
            addButtonText='Add New Task'
            handleEdit={(data) => {
              handleEdit(data);
            }}
            handleDelete={(data) => {
              handleDelete(data);
            }}
            handleAdd={(data) => {
              handleAdd(data);
            }}
            handleExtendTask={(data) => {
              handleExtendTask(data);
            }}
          ></DataTable>
        </LocalizationProvider>
      </div>

      {isSubmitting && (
        <Loader
          open={isSubmitting}
          handleClose={() => setIsSubmitting(false)}
        />
      )}
      <div>
        <BootstrapDialog
          onClose={handleClose}
          aria-labelledby='customized-dialog-title'
          open={open}
        >
          <BootstrapDialogTitle
            id='customized-dialog-title'
            onClose={handleClose}
          >
            Extend Task
          </BootstrapDialogTitle>
          <div className='modalContent'>
            <DialogContent dividers>
              <div style={{ display: 'flex' }}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label='Current End Date'
                    fullwidth='true'
                    variant='outlined'
                    margin='normal'
                    value={currentEndDate}
                    onChange={(date) => {
                      setCurrentEndDate(date.$d);
                    }}
                    readOnly={true}
                    renderInput={(params) => <TextField {...params} />}
                  />{' '}
                  &nbsp;
                  <DatePicker
                    label='Requested End Date'
                    fullwidth='true'
                    variant='outlined'
                    margin='normal'
                    value={extendEndDate}
                    onChange={(date) => {
                      setExtendEndDate(date.$d);
                    }}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
              </div>
              <br />
              <TextArea
                margin='normal'
                variant='outlined'
                required
                fullwidth='true'
                value={reasonForExtend}
                id='reasonForExtend'
                placeholder='Reason for extend task'
                defaultValue='Reason For extend Task'
                name='reasonForExtend'
                autoComplete='reasonForExtend'
                sx={{ mt: 3 }}
                onChange={handleInputChange}
              />
            </DialogContent>
          </div>
          <DialogActions>
            <Button
              autoFocus
              onClick={() =>
                ExtendTask({
                  endDate: endDate,
                  extendEndDate: extendEndDate,
                  reasonForExtend: reasonForExtend,
                })
              }
              variant='outlined'
            >
              Send Request
            </Button>
          </DialogActions>
        </BootstrapDialog>
      </div>
    </>
  );
}
